import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLazyQuery } from "@apollo/react-hooks"
// bca expo form
import { withFormik, Form, Field } from "formik"
import Select from "react-select"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { Carousel } from "../../components/utils/render-functions"
import Modal from "react-modal"
import OtpInput from "react-otp-input"
import { useMediaPredicate } from "react-media-hook"
// end bca expo form

import "../../graphql/fragments"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import { CoverMain } from "../../components/cover-main/cover-main"
import { HeadingBasic } from "../../components/heading/heading"
import { Section, Container } from "../../components/grid/grid"
import { Cover } from "../../components/cover/cover"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_OTP = gql`
  mutation getOtp($phoneNumber: String) {
    requestOtp(input: { clientMutationId: "", phoneNumber: $phoneNumber }) {
      status
      message
    }
  }
`

const GET_PHONE_EMAIL = gql`
  query formBCAExpo {
    formBCAExpo {
      email
      phoneNumber
    }
  }
`

const SUBMIT_FORM = gql`
  mutation Submit2BcaExpo(
    $fullName: String
    $phoneNumber: String
    $email: String
    $product: String
    $otpCode: String
  ) {
    submit2BcaExpo(
      input: {
        clientMutationId: ""
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
        product: $product
        otpCode: $otpCode
      }
    ) {
      status
      message
    }
  }
`
const listOfProduct = [
  { label: "91 District, BSD City" , value: "91 District, BSD City"} ,
  { label: "Acropolis - Legenda Wisata" , value: "Acropolis - Legenda Wisata"} ,
  { label: "Aerium Residences, Jakarta" , value: "Aerium Residences, Jakarta"} ,
  { label: "Aether, BSD City" , value: "Aether, BSD City"} ,
  { label: "Alesha, BSD City" , value: "Alesha, BSD City"} ,
  { label: "Alexandria, Wisata Bukit Mas, Surabaya" , value: "Alexandria, Wisata Bukit Mas, Surabaya"} ,
  { label: "Alfiore, Taman Banjar Wijaya" , value: "Alfiore, Taman Banjar Wijaya"} ,
  { label: "Amarine, BSD City" , value: "Amarine, BSD City"} ,
  { label: "Amata, BSD City" , value: "Amata, BSD City"} ,
  { label: "Anarta, BSD City" , value: "Anarta, BSD City"} ,
  { label: "Anila House, BSD City" , value: "Anila House, BSD City"} ,
  { label: "Asatti, BSD City" , value: "Asatti, BSD City"} ,
  { label: "Assana House, BSD City" , value: "Assana House, BSD City"} ,
  { label: "Autoparts, BSD City" , value: "Autoparts, BSD City"} ,
  { label: "Azura House, BSD City" , value: "Azura House, BSD City"} ,
  { label: "Blossom Ville Ex, BSD City" , value: "Blossom Ville Ex, BSD City"} ,
  { label: "Breezy House, BSD City" , value: "Breezy House, BSD City"} ,
  { label: "Caelus, BSD City" , value: "Caelus, BSD City"} ,
  { label: "Capital Cove Business Loft, BSD City" , value: "Capital Cove Business Loft, BSD City"} ,
  { label: "Carribean Island, BSD City" , value: "Carribean Island, BSD City"} ,
  { label: "Casa De Parco, BSD City" , value: "Casa De Parco, BSD City"} ,
  { label: "Castilla Extention, BSD City" , value: "Castilla Extention, BSD City"} ,
  { label: "Cattleya, BSD City" , value: "Cattleya, BSD City"} ,
  { label: "Cheville, Balikpapan" , value: "Cheville, Balikpapan"} ,
  { label: "Cloverville Kavling, Surabaya" , value: "Cloverville Kavling, Surabaya"} ,
  { label: "Costa Rica, BSD City" , value: "Costa Rica, BSD City"} ,
  { label: "Cozmo House Ext, BSD City" , value: "Cozmo House Ext, BSD City"} ,
  { label: "De Maja, BSD City" , value: "De Maja, BSD City"} ,
  { label: "Du lyon, Wisata Bukit Mas, Surabaya" , value: "Du lyon, Wisata Bukit Mas, Surabaya"} ,
  { label: "Flathouse, BSD City" , value: "Flathouse, BSD City"} ,
  { label: "Fleekhauz R, BSD City" , value: "Fleekhauz R, BSD City"} ,
  { label: "Foglio, BSD City" , value: "Foglio, BSD City"} ,
  { label: "Freja House, BSD City" , value: "Freja House, BSD City"} ,
  { label: "Freja Suites, BSD City" , value: "Freja Suites, BSD City"} ,
  { label: "Garden Suite, Surabaya" , value: "Garden Suite, Surabaya"} ,
  { label: "Giardina, BSD City" , value: "Giardina, BSD City"} ,
  { label: "Giri Loka, BSD City" , value: "Giri Loka, BSD City"} ,
  { label: "Golden Boulevard, Balikpapan" , value: "Golden Boulevard, Balikpapan"} ,
  { label: "Golden Viena, BSD City" , value: "Golden Viena, BSD City"} ,
  { label: "Grand Palais, Wisata Bukit Mas, Surabaya" , value: "Grand Palais, Wisata Bukit Mas, Surabaya"} ,
  { label: "Hayfield, Grand City, Balikpapan" , value: "Hayfield, Grand City, Balikpapan"} ,
  { label: "Hyland, Balikpapan" , value: "Hyland, Balikpapan"} ,
  { label: "Hylands BSD City" , value: "Hylands BSD City"} ,
  { label: "IMAJIHAUS, BSD City" , value: "IMAJIHAUS, BSD City"} ,
  { label: "Ingenia, BSD City" , value: "Ingenia, BSD City"} ,
  { label: "Inspirahaus R, BSD City" , value: "Inspirahaus R, BSD City"} ,
  { label: "Inspirahaus, BSD City" , value: "Inspirahaus, BSD City"} ,
  { label: "Invensihaus R, BSD City" , value: "Invensihaus R, BSD City"} ,
  { label: "Invensihaus, BSD City" , value: "Invensihaus, BSD City"} ,
  { label: "Jadeite, BSD City" , value: "Jadeite, BSD City"} ,
  { label: "Kavling The Nove, Batam" , value: "Kavling The Nove, Batam"} ,
  { label: "Kios Pasar Modern 2/ Barat, BSD City" , value: "Kios Pasar Modern 2/ Barat, BSD City"} ,
  { label: "Kios Pasar Modern, BSD City" , value: "Kios Pasar Modern, BSD City"} ,
  { label: "Klaska Residence, Surabaya" , value: "Klaska Residence, Surabaya"} ,
  { label: "Lumihous, Kota Wisata, Cibubur" , value: "Lumihous, Kota Wisata, Cibubur"} ,
  { label: "Luxmore, BSD City" , value: "Luxmore, BSD City"} ,
  { label: "Mayfield, BSD City" , value: "Mayfield, BSD City"} ,
  { label: "Mississippi, Kota Wisata, Cibubur" , value: "Mississippi, Kota Wisata, Cibubur"} ,
  { label: "Monte Carlo, BSD City" , value: "Monte Carlo, BSD City"} ,
  { label: "Nusa Loka Park, BSD City" , value: "Nusa Loka Park, BSD City"} ,
  { label: "Paddington, Surabaya" , value: "Paddington, Surabaya"} ,
  { label: "Palais de Luxembourg, Wisata Bukit Mas, Surabaya" , value: "Palais de Luxembourg, Wisata Bukit Mas, Surabaya"} ,
  { label: "Patagonia Village, BSD City" , value: "Patagonia Village, BSD City"} ,
  { label: "Pineville, BSD City" , value: "Pineville, BSD City"} ,
  { label: "Precia, BSD City" , value: "Precia, BSD City"} ,
  { label: "Provence  Suites, BSD City" , value: "Provence  Suites, BSD City"} ,
  { label: "Quantis, BSD City" , value: "Quantis, BSD City"} ,
  { label: "Royal Blossom, BSD City" , value: "Royal Blossom, BSD City"} ,
  { label: "Ruko A6, Balikpapan" , value: "Ruko A6, Balikpapan"} ,
  { label: "Ruko ICE Business Park Tahap 2, BSD City" , value: "Ruko ICE Business Park Tahap 2, BSD City"} ,
  { label: "Ruko Loka 65, BSD City" , value: "Ruko Loka 65, BSD City"} ,
  { label: "Ruko Madison, Kota Wisata, Cibubur" , value: "Ruko Madison, Kota Wisata, Cibubur"} ,
  { label: "Ruko Madrid X, BSD City" , value: "Ruko Madrid X, BSD City"} ,
  { label: "Ruko Pasar Modern Barat, BSD City" , value: "Ruko Pasar Modern Barat, BSD City"} ,
  { label: "Ruko Pasar Modern Timur 2, BSD City" , value: "Ruko Pasar Modern Timur 2, BSD City"} ,
  { label: "Ruko Pro. Suites, BSD City" , value: "Ruko Pro. Suites, BSD City"} ,
  { label: "Ruko The Savia, BSD City" , value: "Ruko The Savia, BSD City"} ,
  { label: "Savia Park, BSD City" , value: "Savia Park, BSD City"} ,
  { label: "Southgate, Jakarta" , value: "Southgate, Jakarta"} ,
  { label: "Street of London, Wisata Bukit Mas, Surabaya" , value: "Street of London, Wisata Bukit Mas, Surabaya"} ,
  { label: "Street of Paris, Wisata Bukit Mas, Surabaya" , value: "Street of Paris, Wisata Bukit Mas, Surabaya"} ,
  { label: "Tabespot, BSD City" , value: "Tabespot, BSD City"} ,
  { label: "Taman Tekno X, BSD City" , value: "Taman Tekno X, BSD City"} ,
  { label: "The Elements, Jakarta" , value: "The Elements, Jakarta"} ,
  { label: "The Eminent, BSD City" , value: "The Eminent, BSD City"} ,
  { label: "Tower Kaina, Nuvasa Bay, Batam" , value: "Tower Kaina, Nuvasa Bay, Batam"} ,
  { label: "Tower Kalani, Nuvasa Bay, Batam" , value: "Tower Kalani, Nuvasa Bay, Batam"} ,
  { label: "Virginia Arcade, BSD City" , value: "Virginia Arcade, BSD City"} ,
  { label: "Visana at The Savia, BSD City" , value: "Visana at The Savia, BSD City"} ,
  { label: "Vivacia, BSD City" , value: "Vivacia, BSD City"} ,
  { label: "West Covina II, Kota Wisata, Cibubur" , value: "West Covina II, Kota Wisata, Cibubur"} ,
  { label: "West Park, BSD City" , value: "West Park, BSD City"} ,
  { label: "Whitsand II, BSD City" , value: "Whitsand II, BSD City"} ,
  { label: "Yuthica, BSD City" , value: "Yuthica, BSD City"} ,
  { label: "Zena, BSD City" , value: "Zena, BSD City"} ,
]

const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
  const phoneNumberHandler = (val, setFieldValue) =>
    setFieldValue("phoneNumber", val)

    const [checked, setChecked] = useState(false)

    function handleOnChange(){
      setChecked(!checked)
    }

  return (
    <Form className="row pt-4" id="surpriseForm">
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nama</label>
        <Field
          name="name"
          className="form-control px-0"
          placeholder="Nama Anda"
          type="text"
          id="customerName"
          value={values.fulllName}
        />
        {touched.name && errors.name && (
          <div className="invalid-value d-block">{errors.name}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Nomor Handphone</label>
        <NumberFormat
          name="phoneNumber"
          className="form-control px-0"
          placeholder="Nomor Handphone"
          allowEmptyFormatting
          prefix="+62"
          value={values.phoneNumber}
          onValueChange={(val) =>
            phoneNumberHandler(val.formattedValue, setFieldValue)
          }
          id="customerPhone"
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <div className="invalid-value d-block">{errors.phoneNumber}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Email</label>
        <Field
          name="email"
          className="form-control px-0"
          placeholder="Email Anda"
          type="text"
          id="customerEmail"
          value={values.email}
        />
        {touched.email && errors.email && (
          <div className="invalid-value d-block">{errors.email}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3">
        <label className="text-muted my-0">Produk Yang Diminati</label>
        <Select
          maxMenuHeight={150}
          className="form-control px-0"
          classNamePrefix="Produk Yang Diminati"
          options={listOfProduct}
          defaultValue={{ value: "", label: "Produk Yang Diminati" }}
          id="product"
          onChange={(e) => {
            setFieldValue("product", e.value)
          }}
        />
        {touched.product && errors.product && (
          <div className="invalid-value d-block">{errors.product}</div>
        )}
      </div>
      <div className="col-12 col-md-12 pb-3 pt-3">
        <Field 
          type="checkbox" 
          name="tnc" 
          value="tnc"
          id="tnc"
          onClick={handleOnChange}
        />
        &nbsp;<span className="tnc-giias">Saya setuju dengan syarat & ketentuan yang berlaku.</span><span className="red-star" style={{color:"#ab0204"}}>*</span>
      </div>
      <div className="col-12 pt-5">
        <button className="btn btn-primary w-100 w-md-25">
          {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
        </button>
      </div>
    </Form>
  )
}

const BcaExpoPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerResidence.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgPropertyFinder: file(relativePath: { eq: "residence-finder.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      featured: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        page(id: "bca-expo", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          blocks {
            name
            ... on WordPress_CoreHeadingBlock {
              attributes {
                content
              }
            }
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
            ... on WordPress_CoreParagraphBlock {
              attributes {
                ... on WordPress_CoreParagraphBlockAttributesV3 {
                  content
                }
              }
            }
            ... on WordPress_AcfAcfgalleryBlock {
              name
              acf {
                carousel_type
                images {
                  title
                  mediaDetails {
                    file
                    height
                    width
                  }
                  slug
                  sourceUrl
                  acfAttachment {
                    location
                    expired
                  }
                  caption(format: RAW)
                  description(format: RAW)
                }
              }
            }
            ... on WordPress_CoreEmbedBlock {
              attributes {
                url
              }
            }
          }
          translation(language: ID) {
            title
            blocks {
              name
              ... on WordPress_CoreHeadingBlock {
                attributes {
                  content
                }
              }
              ... on WordPress_CoreCoverBlock {
                attributes {
                  url
                  className
                }
              }
              ... on WordPress_CoreParagraphBlock {
                attributes {
                  ... on WordPress_CoreParagraphBlockAttributesV3 {
                    content
                  }
                }
              }
              ... on WordPress_AcfAcfgalleryBlock {
                name
                acf {
                  carousel_type
                  images {
                    title
                    mediaDetails {
                      file
                      height
                      width
                    }
                    slug
                    sourceUrl
                    acfAttachment {
                      location
                      expired
                    }
                    caption(format: RAW)
                    description(format: RAW)
                  }
                }
              }
              ... on WordPress_CoreEmbedBlock {
                attributes {
                  url
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const ogUrl = process.env.BASE_URL + location.pathname + "/"
  const wordPress = staticData.wordPress.page
  const seo = staticData.wordPress.page?.seo
  const resizeScreen = useMediaPredicate("(max-width: 992px)")

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    product: "",
    tnc: "",
  })
  
  const [showModal, setShowModal] = useState(false)
  const [otp, setOtp] = useState()
  // eslint-disable-next-line
  const [successModal, setSuccessModal] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [timeLimit, setTimeLimit] = useState(30)

  // bca expo form
  const [getPhoneEmail, { data }] = useLazyQuery(GET_PHONE_EMAIL)
  const [submitForm, { loading }] = useMutation(SUBMIT_FORM)
  const [getOtp, { loading: getOtpLoading }] = useMutation(GET_OTP)

  let coverItems = []
  // eslint-disable-next-line
  wordPress && wordPress?.blocks?.map(item => {
    if(item.name === "core/cover"){
      coverItems.push({url: item.attributes.url, className: item.attributes.className})
    }
  })

  const iframeTriggerUpdate = () => {
    console.log("start_fork")
    var a = document.createElement("iframe")
    a.setAttribute("src","https://fork.gotrackier.com/pixel?adid=64feaf06cfbccb20802b07d1&goal_value=SubmitButton")
    a.style.width="1"
    a.style.height="1"
    document.body.appendChild(a)
    console.log("end_fork")
  }

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    let vLine = document.querySelector(".v-line")
    let langItem = document.querySelectorAll(".lang-item")
    let langItemActive = document.querySelector(".lang-item.active")
      
    if(resizeScreen){
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }

    for (let i = 0; i < langItem.length; i++) {
      langItem[i].style.color = "#000"
    }

    vLine.style.backgroundColor = "#000"
    langItemActive.style.color = "#AB0204"
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    wordPress && wordPress?.blocks?.map(item => {
      if(item.name === "core/cover"){
        coverItems.push({url: item.attributes.url, className: item.attributes.className})
      }
    })
  }, [])

  useEffect(() => {
    getPhoneEmail()
  }, [])

  useEffect(() => {
    let coverPage = document.querySelectorAll(".bg-overlay.bg-overlay-40")

    for (let i = 0; i < coverPage.length; i++) {
      coverPage[i].className = "bg-overlay bg-overlay-10"
    }

    if(resizeScreen){
      document.querySelector(".cover-full-top").style.marginTop = "60px"
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }
  }, [])

  useEffect(() => {
    let timer = setTimeout(() => {
      if (timeLimit >= 0 && showModal) {
        setTimeLimit(timeLimit - 1)
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [timeLimit, showModal])

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email, product, tnc, }) {
      return {
        name: formValues.name || name,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        email: formValues.email || email,
        product: formValues.product || product,
        tnc: formValues.tnc || tnc,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string()
        .email()
        .required("Email is required"),
      product: Yup.string().required("Produk Yang Dibeli is required"),
      tnc: Yup.string().required("TnC is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)
      const formatPhone = values?.phoneNumber?.split("+").join("")

      // TODO : reject submit if phoneNumber/email exist
      let dataList = data.formBCAExpo

      // for (let i = 0; i < dataList.length; i++) {
      //   if (
      //     dataList[i].phoneNumber == formatPhone ||
      //     dataList[i].email.trim() == values.email.trim()
      //   ) {
      //     alert("Maaf! Anda tidak bisa mengikuti program kembali")
      //     return false
      //   }
      // }

      iframeTriggerUpdate()
      getOtp({ variables: { phoneNumber: formatPhone } }).then(res => {
        if (res.data.requestOtp.status === "success") {
          setShowModal(true)
        }
      })

      // submitForm({
      //   variables: {
      //     fullName: values?.name,
      //     phoneNumber: formatPhone,
      //     email: values?.email,
      //     product: values?.product,
      //   },
      // })
      //   .then((res) => {
      //     console.log(res)
      //     alert("Terima Kasih, data Anda telah disimpan.")
      //     window.location.reload()
      //   })
      //   .catch((e) => {
      //     console.log(e)
      //     alert("Terjadi kesalahan saat minyimpan data.")
      //   })
    },
  })(LeadForm)

  const handleOtp = otp => {
    setOtp(otp)
  }

  const getOtpHandler = () =>
    getOtp({
      variables: { phoneNumber: formValues.phoneNumber.split("+").join("") },
    }).then(res => {
      if (res.data.requestOtp.status === "success") {
        setTimeLimit(30)
        setShowModal(true)
      }
    })
  
  const sendOtp = () => {
    const formatNumber = formValues.phoneNumber.split("+").join("")

    submitForm({
      variables: {
        fullName: formValues?.name,
        phoneNumber: formatNumber,
        email: formValues?.email,
        product: formValues?.product,
        otpCode: otp,
      },
    }).then((res) => {
      // console.log(res)
      // alert("Terima Kasih, data Anda telah disimpan.")
      // window.location.reload()
      if (res.data.submit2BcaExpo.status === "success") {
        setShowModal(false)
        setSuccessModal(true)
        setTimeLimit(30)
        setFormValues({ fullName: "", email: "", phoneNumber: "", product: "" })
        setOtp("")
        if (otpError) {
          setOtpError(false)
        }
        alert("Terima Kasih, data Anda telah disimpan.")
        window.location.reload()
      }else{
        setOtpError(true)
      }
    }).catch((e) => {
      // console.log(e)
      alert("Terjadi kesalahan saat minyimpan data.")
    })
  }

  // const cover = wordPress?.blocks?.find((item) => item.name === "core/cover")
  const featuredImage = staticData?.wordPress?.page?.featuredImage?.mediaItemUrl

  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        url={ogUrl}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        img={featuredImage}
        fbImg={featuredImage}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <CoverMain
        h1={""}
        img={resizeScreen ? coverItems[1].url : coverItems[0]?.url}
        alt={wordPress?.title}
        className={`cover-md ${resizeScreen ? coverItems[1]?.className : coverItems[0]?.className} cover-header-70`}
      />
      {wordPress?.blocks.map((item, i) => {
        switch (item.name) {
          case "core/heading":
            return (
              <div key={`heading-${i}`} className="container container-md">
                <div className={i === 0 ? "py-main pb-3 pt-4" : "p-0"}>
                  <HeadingBasic h2={item.attributes.content} />
                </div>
              </div>
            )
          case "core/paragraph":
            return (
              <div
                key={`headingbasic-${i}`}
                className={`py-main pb-3 pt-3`}
              >
                <div className="container container-md">
                  {/* <HeadingBasic h2={wordPress?.title} /> */}
                  <HeadingBasic text={[item.attributes.content]} />
                </div>
              </div>
            )
          case "acf/acfgallery":
            return (
              <Container
                className={"pb-main"}
                key={`gallery-${i}`}
              >
                <Carousel data={item} />
              </Container>
            )
          case "core/embed":
            return (
              <div className="container py-main">
                <Cover
                  variant="basic"
                  theme="dark"
                  img={null}
                  imgHeight="h-400px h-md-500px w-100"
                  // imgOverlay="40"
                  className="cover cover-full-top"
                  // type="video-popup"
                  videoSrc={item.attributes && item.attributes.url}
                  type={{ name: "core-embed/youtube" }}
                  audio={false}
                />
              </div>
            )
          default:
            return <></>
        }
      })}
      <div
        className={`mb-5`}
      >
        <div className="container container-md">
          <HeadingBasic h2={"Saya Ingin Penawaran Terbaik"} />
          <EnhancedLeadForm />
        </div>
      </div>
      <Modal
        isOpen={showModal}
        style={customStyles}
        contentLabel="campaign modal"
      >
        <div className="modal-popup-otp modal-body text-center">
          <div className="container py-5 mw-md-75 mw-100">
            <h2>OTP</h2>
            <p className="m-0">
              Untuk melanjutkan, silakan masukkan 6 digit kode yang
              dikirimkan ke nomor ponsel Anda
            </p>
            <p className="font-weight-bold">{formValues.phoneNumber}</p>
            <OtpInput
              value={otp}
              containerStyle="otp-container"
              inputStyle={`otp-input ${otpError ? "error" : ""}`}
              onChange={handleOtp}
              numInputs={6}
            />
            {otpError && (
              <p className="otp-error mt-3">
                The code you entered does not match, please re-enter.
              </p>
            )}
            {getOtpLoading ? (
              <p className="mt-md-5 mt-3 text-primary">
                <i className="fa fa-spinner fa-spin"></i>
              </p>
            ) : timeLimit > 0 ? (
              <p className="mt-md-5 mt-3 text-muted">
                Tidak menerima kode OTP? kirim ulang ({timeLimit})
              </p>
            ) : (
              <button
                className="btn mt-md-5 mt-3 text-primary"
                onClick={getOtpHandler}
              >
                Haven’t received OTP code? <strong>Resend</strong>
              </button>
            )}
            <button className="btn btn-primary w-50 mt-3" onClick={sendOtp}>
              {loading ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}
export default BcaExpoPage
